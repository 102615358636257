const config = {
        hideBooking: true,
        hideProductAndDocuments: true,
        hideProduct: true,
        hideRetailMod: true,
        hideCreateButton: true,
        hideLimitCounter: true,
        hideOcr: true,
        hideSign: true,
};

const env = [
        {key: 'hideBooking', value: process.env.VUE_APP_HIDE_BOOKING, default: true},
        {key: 'hideProductAndDocuments', value: process.env.VUE_APP_HIDE_DOC_BLOCK, default: false},
        {key: 'hideProduct', value: process.env.VUE_APP_HIDE_PRODUCT, default: true},
        {key: 'hideRetailMod', value: process.env.VUE_APP_HIDE_RETAIL_MOD, default: true},
        {key: 'hideCreateButton', value: process.env.VUE_APP_HIDE_CREATE_BTN, default: false},
        {key: 'hideLimitCounter', value: process.env.VUE_APP_HIDE_LIMIT, default: true},
        {key: 'hideOcr', value: process.env.VUE_APP_HIDE_OCR, default: true},
        {key: 'hideSign', value: process.env.VUE_APP_HIDE_SIGN, default: false},
]

env.forEach((param) => {
        let value = param.default;
        if(typeof param.value !== undefined && typeof param.value === "string") {
                if(param.value === 'false') value = false;
                if(param.value === 'true') value = true;
        }
        config[param.key] = value;
})

module.exports = config;

